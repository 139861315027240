import React from "react"
import { graphql } from "gatsby"
import { Box, Flex } from "@rebass/emotion"
import GatsbyImage from "gatsby-image"
import Carousel, { Modal, ModalGateway } from "react-images"
import { css } from "@emotion/core"

import {
  Layout,
  Container,
  SEO,
  Text,
  Section,
  DecoratedHeading,
  Hero,
} from "../components"

export const pageQuery = graphql`
  query($slug: String!) {
    prismicInitiative(slugs: { eq: $slug }) {
      data {
        title {
          text
        }
        article {
          html
        }
        hero {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 4160) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        gallery {
          image {
            localFile {
              childImageSharp {
                fixed(quality: 90, width: 245, height: 245) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        fullImages: gallery {
          image {
            localFile {
              childImageSharp {
                fixed(quality: 90, width: 1000) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function Initiative({ data }) {
  let [modalIsOpen, setModalOpen] = React.useState(false)
  let [galleryIndex, setGalleryIndex] = React.useState(null)

  const pageData = data.prismicInitiative.data

  const fullImages = pageData.fullImages.map(item => ({
    src: item.image.localFile.childImageSharp.fixed.src,
  }))

  const openModal = index => {
    setGalleryIndex(index)
    setModalOpen(true)
  }

  return (
    <Layout>
      <SEO
        title={`Initiatives | ${pageData.title.text}`}
        keywords={[`ELI`, pageData.title.text]}
      />
      <Hero fluid={pageData.hero.localFile.childImageSharp.fluid}>
        <Container>
          <DecoratedHeading type="cornerWhite">
            {pageData.title.text}
          </DecoratedHeading>
        </Container>
      </Hero>
      <Container>
        <Section>
          <Box>
            <Text
              as="div"
              dangerouslySetInnerHTML={{ __html: pageData.article.html }}
            />
          </Box>
        </Section>
        <Section>
          <Box
            css={css`
              text-align: center;
            `}
          >
            <DecoratedHeading h3>Gallery</DecoratedHeading>
            <Flex flexWrap="wrap">
              {pageData.gallery.map((item, i) => (
                <Box
                  onClick={() => openModal(i)}
                  width={[1, 1 / 2, 1 / 3, 1 / 4]}
                  p="3"
                  key={`galleryImage_${i}`}
                  css={css`
                    cursor: pointer;
                  `}
                >
                  <GatsbyImage
                    fixed={item.image.localFile.childImageSharp.fixed}
                  />
                </Box>
              ))}
            </Flex>
            <ModalGateway>
              {modalIsOpen ? (
                <Modal onClose={() => setModalOpen(false)}>
                  <Carousel
                    views={fullImages}
                    currentIndex={galleryIndex}
                    styles={{
                      header: base => ({
                        ...base,
                        paddingTop: 50,
                      }),
                    }}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </Box>
        </Section>
      </Container>
    </Layout>
  )
}
